<template>
  <div>
    <h3 class="title">Selecione a data desejada</h3>
    <div class="my-5">
      <v-virtual-scroll
        v-if="hasDays"
        :items="slotDays"
        :item-height="70"
        :height="500"
        class="items-border-bottom-grey"
      >
        <template #default="{ item }">
          <v-list-item two-line @click="selectDay(item)">
            <v-list-item-avatar>
              <v-icon>today</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.date | formatDate }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ dayOfMonth(item.date) }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-virtual-scroll>
      <template v-else>
        <empty-list text="Nenhuma data disponível para este terapeuta." />
      </template>
    </div>
    <stepper-buttons />
  </div>
</template>

<script>
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "dayjs/locale/pt-br";
dayjs.extend(customParseFormat);

import { mapState } from "vuex";
export default {
  name: "StepDateTime",
  components: {
    StepperButtons: () =>
      import(
        "@/domains/appointments/patient/presentation/components/stepper/StepperButtons"
      ),
  },
  computed: {
    ...mapState("appointments/patient", ["slotDays"]),
    hasDays() {
      return this.slotDays && this.slotDays.length > 0;
    },
  },
  methods: {
    selectDay({ date, appointmentId }) {
      this.$store.dispatch("appointments/patient/selectDate", {
        date,
        appointmentId,
      });
    },
    dayOfMonth(date) {
      return dayjs(date).locale("pt-br").format("dddd");
    },
  },
};
</script>

<style scoped></style>
